import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import AdemeLogoSvg from "../../images/ademe-logo-2022-1.svg";
import { GatsbyImage } from "gatsby-plugin-image";

type FooterQuery = {
  wp: {
    footerOptions: {
      footer: NonNullable<
        NonNullable<
          NonNullable<Queries.FooterQuery["wp"]>["footerOptions"]
        >["footer"]
      >;
    };
  };
};
const FooterNavigation = () => {
  const homePath = "/";

  const {
    wp: {
      footerOptions: {
        footer: {
          address,
          addressTitle,
          socialNetworks,
          links,
          legalMentionsPage,
          contactPage,
          urlAdeme,
          logoAdeme,
        },
      },
    },
  } = useStaticQuery<FooterQuery>(graphql`
    query Footer {
      wp {
        footerOptions {
          footer {
            address
            addressTitle
            socialNetworks {
              url
            }
            links {
              link {
                url
                title
                target
              }
            }
            legalMentionsPage {
              nodes {
                ... on WpPage {
                  uri
                  title
                }
              }
            }
            contactPage {
              nodes {
                ... on WpPage {
                  uri
                  title
                }
              }
            }
            logoAdeme {
              node {
                uri
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 304)
                  }
                }
              }
            }
            urlAdeme
          }
        }
      }
    }
  `);

  const logoAdemeImageData =
    logoAdeme?.node?.localFile?.childImageSharp?.gatsbyImageData;

  const legalMentionsLink = { ...legalMentionsPage?.nodes[0] };
  const contactLink = { ...contactPage?.nodes[0] };

  return (
    <div className="section" id="sectionFooter">
      <footer className="container-fluid bg-grey pt-3">
        <div className="container ">
          <div className="row footer-info">
            <div className="col-md-3 ">
              <h6>{addressTitle}</h6>
              <address
                dangerouslySetInnerHTML={{
                  __html: (address || "").replace("\n", "<br/>\n"),
                }}
              />
            </div>
            <div className="col-md-3">
              <h6>
                <Link to={legalMentionsLink.uri || "/"}>
                  {legalMentionsLink.title}
                </Link>
              </h6>
              <Link to={contactLink.uri || "/"} className="btn-footer">
                {contactLink.title}
              </Link>
              {urlAdeme && (
                <div className="ademe">
                  <a href={urlAdeme} target="_blank" rel="noopener noreferrer">
                    {logoAdemeImageData && (
                      <GatsbyImage
                        alt={"Avec le financement de l'ADEME"}
                        image={logoAdemeImageData}
                      />
                    )}
                  </a>
                </div>
              )}
            </div>
            <div className="col-md-3 d-none d-md-block">
              <h6> Liens </h6>
              <div className="row ">
                <div className="col-md-12">
                  <ul>
                    {links &&
                      links.filter(Boolean).map((link, key) => {
                        const {
                          url = "",
                          title = "",
                          target = "",
                        } = {
                          ...link?.link,
                        };
                        return (
                          <li key={key}>
                            <Link
                              to={url || "/"}
                              target={target || "_self"}
                              rel="noopener noreferrer"
                            >
                              {title}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3 ">
              <h6> Réseaux sociaux</h6>
              <div className="social">
                {(socialNetworks as { url: string }[]).map(({ url }, key) => {
                  let name = "link";
                  if (/facebook\.com/.test(url)) {
                    name = "Facebook";
                  } else if (/linkedin\.com/.test(url)) {
                    name = "LinkedIn";
                  } else if (/instagram\.com/.test(url)) {
                    name = "Instagram";
                  } else if (/(x\.com|twitter\.com)/.test(url)) {
                    name = "X";
                  }
                  const icon = name.toLowerCase();
                  return (
                    <a href={url} aria-label={name} key={key}>
                      <i className={`fa fa-${icon}`} aria-hidden="true"></i>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FooterNavigation;
